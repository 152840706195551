<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
    font-size: 15px;
    flex-shrink: 0;
    text-decoration: none;
}

*::-webkit-scrollbar {
    display: none;
}

@font-face {
    font-family: "surain";
    src: url("./assets/font.ttf"),
        url("./assets/font.ttf"),
        url("./assets/font.ttf");
}
</style>
